<template>
  <b-media no-body>
    <ProductThumbnail :product="product" />
    <b-media-body>
      <p v-if="product.vigieId" class="h6 text-muted mb-0">
        <span>Code produit: {{ product.vigieId }}</span>
      </p>
      <p v-if="product.isbn13 || product.isbn10" class="h6 text-muted mb-0">
        <span>ISBN: {{ product.isbn13 || product.isbn10 }}</span>
      </p>
      <p class="h5 mb-0 text-wrap">{{ product.name }}</p>
      <p v-if="item.suggestedQuantity" class="h6 text-muted mb-0">
        <span>Obligatoire: {{ requiredString }}</span>
        <br />
        <span>Quantité suggérée: {{ item.suggestedQuantity }}</span>
        <br />
        <span>
          Nombre d'étudiant qui devront se procurer l'article:
          {{ item.neededStudentsQuantity }}
        </span>
        <br />
        <span v-if="$route.params.type == 'course'">
          Étudiants inscrits: {{ item.nbStudents }}<br />
        </span>
        <span>
          Produit obligatoire lors d’un achat via la liste scolaire:
          {{ item.isMandatory ? 'Oui' : 'Non' }}
          <br />
        </span>
      </p>
    </b-media-body>
  </b-media>
</template>

<script>
import ProductThumbnail from '@/components/Product/ProductThumbnail'

export default {
  components: {
    ProductThumbnail,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
    itemId: {
      type: String,
      required: true,
    },
  },
  computed: {
    item() {
      const index = this.category.ListCategoryItems.findIndex(
        (item) => item.id === this.itemId
      )
      return this.category.ListCategoryItems[index]
    },
    product() {
      return this.item.products[0]
    },
    requiredString() {
      return this.item.required ? 'oui' : 'non'
    },
  },
}
</script>
